import classNames from 'classnames/bind';

import Button from '@/components/form/button/Button';
import Icon, { IconSize, IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';
import PageHeader from '../../typography/page-header/PageHeader';

const cx = classNames.bind(styles);

interface Props {
    message?: string;
    title?: string;
    iconType?: IconType;
    buttonLabel?: string;
    buttonHref?: string;
}

const GenericMessage: React.FC<Props> = ({
    message,
    title,
    iconType,
    buttonLabel,
    buttonHref,
}) => {
    return (
        <div className={cx('__generic-container')}>
            {iconType ? (
                <div className={cx('__icon')}>
                    <Icon name={iconType} size={IconSize.XLarge} />
                </div>
            ) : null}
            {title ? <PageHeader h2={title} textAlign='center' /> : null}
            {message ? <div className={cx('__message')}>{message}</div> : null}
            {buttonLabel && buttonHref ? (
                <div>
                    <Button
                        label={buttonLabel}
                        href={buttonHref}
                        theme='primary'
                    />
                </div>
            ) : null}
        </div>
    );
};

export default GenericMessage;
