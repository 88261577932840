import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import CarouselSlide from '@/components/containers/carousel/CarouselSlide';
import Tile from '@/components/tile/Tile';
import { genUid } from '@/utils/helper';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    type: ListItemType;
    amount: number;
}

const GroupedSkeleton: React.FC<Props> = ({ type, amount }) => {
    const [tiles, setTiles] = useState<TileProps[]>([]);

    useEffect(() => {
        const list: TileProps[] = [];

        for (let t = 0; t < amount; t++) {
            list.push({
                id: genUid(),
                type,
                onPress: () => {},
            });
        }

        setTiles(list);
    }, [amount, type]);

    return tiles.map((tile, idx) => (
        <CarouselSlide key={`grouped-result-${tile.id}-${idx}`}>
            <div className={cx('__grouped-result-tile')}>
                <Tile
                    id={tile?.id}
                    type={tile.type}
                    image={`${process?.env?.NEXT_PUBLIC_APP_ORIGIN}/images/global/festgps-h.png`}
                    onPress={tile.onPress}
                    busy={true}
                />
            </div>
        </CarouselSlide>
    ));
};

export default React.memo(GroupedSkeleton);
