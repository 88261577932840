import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import Button from '@/components/form/button/Button';
import { IconType } from '@/components/icon/Icon';
import { debounce } from '@/utils/helper';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    enabled?: boolean;
    children: ReactNode;
    onScrollHandler?: () => void;
}

const Carousel: React.FC<Props> = ({
    enabled = true,
    children,
    onScrollHandler,
}) => {
    const [prevPageEnabled, isPrevPageEnabled] = useState(false);
    const [nextPageEnabled, isNextPageEnabled] = useState(false);

    const totalPages = useRef(0);
    const currentPage = useRef(1);
    const scrollContainer = useRef<HTMLDivElement>(null);
    const scrollContainerWidth = useRef(0);

    const calcPerPage = useCallback(() => {
        const container = scrollContainer?.current;

        if (container) {
            const containerWidth = container.offsetWidth;
            scrollContainerWidth.current = containerWidth;

            const slides = container.querySelectorAll<HTMLDivElement>(
                'div[class*="carousel__slide"]'
            );
            const slide: HTMLDivElement = slides[0];

            if (slide) {
                const perPage = Math.round(containerWidth / slide.offsetWidth);
                const maxPages = Math.ceil(slides.length / perPage);
                totalPages.current = maxPages;

                if (maxPages > 1 && enabled) isNextPageEnabled(true);
            }
        }

        reset();
    }, [enabled]);

    const reset = () => {
        currentPage.current = 1;

        isPrevPageEnabled(false);

        const container = scrollContainer?.current;
        if (container) container.scroll({ left: 0 });
    };

    const showNextItems = () => {
        const container = scrollContainer?.current;

        if (container && scrollContainerWidth) {
            const page = currentPage.current;
            const xPos = scrollContainerWidth.current * page;
            container.scrollTo({ left: xPos, behavior: 'smooth' });

            const nextPage = page + 1;
            currentPage.current = nextPage;

            isPrevPageEnabled(true);

            if (nextPage === totalPages.current) isNextPageEnabled(false);
        }
    };

    const showPrevItems = () => {
        const container = scrollContainer?.current;

        if (container && scrollContainerWidth) {
            const page = currentPage.current - 1;
            const prevPage = page - 1;

            const xPos = scrollContainerWidth.current * prevPage;
            container.scrollTo({ left: xPos, behavior: 'smooth' });

            currentPage.current = page;

            isNextPageEnabled(true);

            if (!prevPage) isPrevPageEnabled(false);
        }
    };

    useEffect(() => {
        const calc = debounce(calcPerPage, 100, false);
        const onScroll = onScrollHandler
            ? debounce(onScrollHandler, 100, false)
            : undefined;
        const slidesContainer = scrollContainer?.current;

        if (enabled) {
            if (typeof document !== 'undefined') {
                window.addEventListener('resize', calc);
                if (onScroll && slidesContainer)
                    slidesContainer.addEventListener('scroll', onScroll);
                calc();
            }
        }

        return () => {
            window.removeEventListener('resize', calc);
            if (onScroll && slidesContainer)
                slidesContainer.removeEventListener('scroll', onScroll);
        };
    }, [enabled, calcPerPage, onScrollHandler]);

    return (
        <div className={cx('carousel-container')}>
            <div
                ref={scrollContainer}
                className={cx('__slides', 'custom__scrollbar')}>
                {children}
            </div>
            {prevPageEnabled ? (
                <div className={cx('__slide-controls', '__prev--slides')}>
                    <Button
                        leftIcon={IconType.ChevronLeft}
                        paddingHorizonal={false}
                        ariaLabel='Show previous items'
                        theme='secondary'
                        size='small'
                        onPress={showPrevItems}
                        circle
                    />
                </div>
            ) : null}
            {nextPageEnabled ? (
                <div className={cx('__slide-controls', '__next--slides')}>
                    <Button
                        leftIcon={IconType.ChevronRight}
                        paddingHorizonal={false}
                        ariaLabel='Show next items'
                        theme='secondary'
                        size='small'
                        onPress={showNextItems}
                        circle
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Carousel;
