import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames/bind';
import _isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';

import GroupedResults from '@/components/containers/grouped-results/GroupedResults';
import PageHeader from '@/components/typography/page-header/PageHeader';
import NoResults from '@/components/indicators/no-results/NoResults';
import Skeleton, { Bone } from '@/components/loading/skeleton/Skeleton';
import $http from '@/utils/http';
import { debounce, isInView } from '@/utils/helper';
import {
    transformArtistResults,
    transformFestivalResults,
    transformPostResults,
} from '@/data/transformer';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    h2: string;
    type: ListItemType;
    maxPerRow: number;
    h2Href?: string;
    subHeading?: string;
    endpoint?: string;
    params?: HTTPRequestParams;
    requestKey?: string;
    tiles?: TileProps[];
    noResultsTitle?: string;
    noResultsMessage?: string;
    noResultsButtonLabel?: string;
    noResultsButtonHref?: string;
    onDataReady?: (data: Record<string, unknown>) => void;
    footer?: ReactNode;
}

const FeaturedCarousel: React.FC<Props> = ({
    h2,
    type,
    maxPerRow,
    h2Href,
    subHeading,
    endpoint,
    params,
    requestKey,
    tiles,
    noResultsTitle,
    noResultsMessage,
    noResultsButtonLabel,
    noResultsButtonHref,
    onDataReady,
    footer,
}) => {
    const router = useRouter();
    const container = useRef(null);

    const hasFetched = useRef(false);
    const [fetching, isFetching] = useState(true);
    const [results, setResults] = useState<TileProps[]>([]);

    const requestParams = useRef<HTTPRequestParams | undefined>();

    const getPageHeaderBones = () => {
        const bones: Bone[] = [];

        if (h2) bones.push({ customClass: 'h-9 md:h-11 w-full md:w-72' });
        if (subHeading)
            bones.push({ customClass: 'h-6 w-32 md:w-48 mt-2 md:mt-4' });

        return bones;
    };

    const checkVisibility = useCallback(async () => {
        if (container.current) {
            const visible = await isInView(container.current);

            if (visible && !hasFetched.current && !tiles) {
                hasFetched.current = true;

                if (!tiles && endpoint) {
                    const responseData = await $http.get({
                        endpoint,
                        params,
                        rateLimit: false,
                        requestKey: requestKey,
                    });

                    requestParams.current = params;

                    if (responseData) {
                        const { data } = responseData;
                        let tiles: TileProps[] = [];

                        if (type === 'festival')
                            tiles = transformFestivalResults(data, router);
                        if (type === 'artist')
                            tiles = transformArtistResults(data, router);
                        if (type === 'post')
                            tiles = transformPostResults(responseData, router);

                        setResults(tiles);
                        onDataReady?.(responseData);
                    }
                }

                isFetching(false);
            }

            if (visible && tiles) {
                setResults(tiles);
                isFetching(false);
            }
        }
    }, [endpoint, onDataReady, params, requestKey, router, tiles, type]);

    useEffect(() => {
        const viewportListener = debounce(checkVisibility, 200, null);

        (async () => {
            if (typeof window !== 'undefined') {
                document.addEventListener('scroll', viewportListener);
                window.addEventListener('resize', viewportListener);
                window.addEventListener('orientationchange', viewportListener);

                await checkVisibility();
            }

            return () => {
                if (typeof window !== 'undefined') {
                    document.addEventListener('scroll', viewportListener);
                    window.removeEventListener('resize', viewportListener);
                    window.removeEventListener(
                        'orientationchange',
                        viewportListener
                    );
                }
            };
        })();
    }, [checkVisibility]);

    return (
        <section ref={container} className={cx('__featured')}>
            {!fetching ? (
                <PageHeader h2={h2} h2Href={h2Href} subHeading={subHeading} />
            ) : (
                <div>
                    <Skeleton bones={getPageHeaderBones()} theme='light' />
                </div>
            )}
            {!fetching &&
            !results.length &&
            noResultsTitle &&
            noResultsMessage ? (
                <div>
                    <NoResults
                        align='left'
                        title={noResultsTitle}
                        message={noResultsMessage}
                        dataSource='generic'
                        buttonLabel={noResultsButtonLabel}
                        buttonHref={noResultsButtonHref}
                    />
                </div>
            ) : (
                <GroupedResults
                    type={type}
                    results={!fetching ? results : []}
                    maxResults={maxPerRow}
                    busy={fetching}
                />
            )}
            {!fetching && footer ? (
                <>{footer}</>
            ) : fetching && footer ? (
                <div>
                    <Skeleton
                        bones={[{ customClass: 'h-12 w-full md:w-40' }]}
                        theme='light'
                    />
                </div>
            ) : null}
        </section>
    );
};

export default React.memo(FeaturedCarousel);
