import classNames from 'classnames/bind';

import Button from '@/components/form/button/Button';
import Carousel from '@/components/containers/carousel/Carousel';
import CarouselSlide from '@/components/containers/carousel/CarouselSlide';
import GroupedSkeleton from './GroupedSkeleton';
import PageHeader from '@/components/typography/page-header/PageHeader';
import Tile from '@/components/tile/Tile';
import { MAX_TAGS_TO_SHOW_IN_RESULTS } from '@/utils/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    type: ListItemType;
    title?: string;
    results: TileProps[];
    maxResults: number;
    totalResults?: number;
    moreHref?: string;
    showMore?: boolean;
    moreLabel?: string;
    busy?: boolean;
}

const GroupedResults: React.FC<Props> = ({
    type,
    title,
    results,
    maxResults,
    totalResults = 0,
    moreHref,
    showMore = false,
    moreLabel = 'More',
    busy = false,
}) => {
    const getShareUrl = (result: TileProps) => {
        if (type === 'festival') {
            return `/festivals/${result?.slug}`;
        }

        if (type === 'artist') {
            return `/artists/${result?.slug}`;
        }

        if (type === 'post') {
            return `/blog/${result?.slug}`;
        }
    };
    return (
        <div className={cx('grouped-result', { '--loading': busy })}>
            <>
                {title || showMore ? (
                    <div className={cx('grouped-result__header')}>
                        {title ? (
                            <PageHeader h2={title} h2Href={moreHref} />
                        ) : null}
                        {showMore && totalResults > maxResults ? (
                            <Button
                                href={moreHref}
                                label={moreLabel}
                                size='small'
                            />
                        ) : null}
                    </div>
                ) : null}
                <Carousel enabled={!busy}>
                    {!busy ? (
                        results
                            .slice(0, maxResults)
                            .map((result: TileProps) => (
                                <CarouselSlide
                                    key={`grouped-result-${result.id}`}>
                                    <div
                                        className={cx('__grouped-result-tile')}>
                                        <Tile
                                            id={result?.id}
                                            type={type}
                                            slug={result?.slug}
                                            title={result?.title}
                                            subTitle={result?.subTitle}
                                            summary={result?.summary}
                                            image={result?.image}
                                            website={result?.website}
                                            inUserCrate={result?.inUserCrate}
                                            spotifyUrl={result?.spotifyUrl}
                                            shareUrl={getShareUrl(result)}
                                            genres={result?.genres?.slice(
                                                0,
                                                MAX_TAGS_TO_SHOW_IN_RESULTS
                                            )}
                                            actionsVisible={type !== 'media'}
                                            getTagUrl={result?.getTagUrl}
                                            onPress={result?.onPress}
                                            showOnScroll={true}
                                        />
                                    </div>
                                </CarouselSlide>
                            ))
                    ) : (
                        <GroupedSkeleton type={type} amount={5} />
                    )}
                </Carousel>
            </>
        </div>
    );
};

export default GroupedResults;
