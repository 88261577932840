import classNames from 'classnames/bind';

import GenericMessage from './GenericMessage';
import { IconType } from '@/components/icon/Icon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    align?: 'left' | 'center';
    dataSource?: 'festivals' | 'artists' | 'locations' | 'generic';
    title?: string;
    iconType?: IconType;
    message?: string;
    buttonLabel?: string;
    buttonHref?: string;
}

const NoResults: React.FC<Props> = ({
    dataSource = 'generic',
    align = 'center',
    title,
    message,
    iconType,
    buttonLabel,
    buttonHref,
}) => {
    return (
        <div
            className={cx('no-results-found__container', `--${align}`, {
                '--generic': dataSource === 'generic',
            })}>
            {!message && dataSource !== 'generic' ? (
                <p className={cx('no-results-found')}>
                    No {dataSource} available
                </p>
            ) : (
                <GenericMessage
                    title={title}
                    message={message}
                    iconType={iconType}
                    buttonLabel={buttonLabel}
                    buttonHref={buttonHref}
                />
            )}
        </div>
    );
};

export default NoResults;
