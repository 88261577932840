import { ReactNode } from 'react';

import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    children: ReactNode;
}

const CarouselSlide: React.FC<Props> = ({ children }) => {
    return <div className={cx('carousel__slide')}>{children}</div>;
};

export default CarouselSlide;
